import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import menuimgone from '../../assets/images/resource/menu-image-1.webp';
import menuimgtwo from '../../assets/images/resource/menu-image-2.webp';
import menuimgthree from '../../assets/images/resource/menu-image-3.webp';
import menuimgfour from '../../assets/images/resource/menu-image-4.webp';
import menuimgfive from '../../assets/images/resource/menu-image-5.webp';

function Kokteyl() {
  const options = {
    slidesPerView: 4,
    spaceBetween: 45,
    loop: true,
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
    autoplay: {
      delay: 7000,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      600: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  };

  return (
    <>
      <section className="special-offer">
        <div className="outer-container">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>BRASSERIE CHAGLAYAN</span>
              </div>
              <div className="pattern-image">
                <img src={require('../../assets/images/icons/separator.svg').default} alt="mySvgImage" />
              </div>
              <h2>İmza Kokteyller</h2>

            </div>
            <Swiper className="dish-gallery-slider " {...options}>
              <SwiperSlide className="offer-block-two">
                <div className="inner-box">
                  <div className="image">
                    <Link to="/">
                      <img src={menuimgone} alt="" />
                    </Link>
                  </div>
                  <h4>
                    <Link to="#">Smiley</Link>
                  </h4>
                  <div className="text desc">Pineapple Gin | Guava Puree | Pink Grapefruit | Triple Sec | Fresh Lime | Black Pepper </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two margin-top">
                <div className="inner-box">
                  <div className="image">
                    <Link to="/">
                      <img src={menuimgtwo} alt="" />
                    </Link>
                  </div>
                  <h4>
                    <Link to="#">IRON</Link>
                  </h4>
                  <div className="text desc">Tullamore D.E.W Irish Whiskey | Dry Vermouth | Aperol Angostura Orange Bitter | Pink Grapefruit | Fresh Lime | Plum & Black Grape</div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="inner-box">
                  <div className="image">
                    <Link to="/">
                      <img src={menuimgthree} alt="" />
                    </Link>
                  </div>
                  <h4>
                    <Link to="#">Beatles</Link>
                  </h4>
                  <div className="text desc">Gin | Campari | Dry Vermouth | Blackberry Puree | Angostura Orange Bitter | Pink Grapefruit | Thyme</div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two margin-top">
                <div className="inner-box">
                  <div className="image">
                    <Link to="/">
                      <img src={menuimgfour} alt="" />
                    </Link>
                  </div>
                  <h4>
                    <Link to="#">BC Negroni</Link>
                  </h4>
                  <div className="text desc">Hendricks Gin | Light Vermouth | Angostura Orange Bitters | Campari Foam</div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="offer-block-two margin-top">
                <div className="inner-box">
                  <div className="image">
                    <Link to="/">
                      <img src={menuimgfive} alt="" />
                    </Link>
                  </div>
                  <h4>
                    <Link to="#">Babi</Link>
                  </h4>
                  <div className="text desc">Aperol | Fresh Lime | Demerara Syrup | Angostura Orange Bitter | Prosecco </div>
                </div>
              </SwiperSlide>

            </Swiper>

            <div className="lower-link-box text-center">
            <a href="https://brasseriechaglayan.com/menu/Menu.pdf" className="theme-btn btn-style-two clearfix" target="_blank" rel="noopener noreferrer">
                <span className="btn-wrap">
                  <span className="text-one">MENÜ</span>
                  <span className="text-two">MENÜ</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Kokteyl;
