import React from 'react'
import { Link } from 'react-router-dom'
import etkinlik1 from '../../assets/images/resource/etkinlik-1.jpg'
import etkinlik2 from '../../assets/images/resource/etkinlik-2.jpg'
import etkinlik3 from '../../assets/images/resource/etkinlik-3.jpg'


function Etkinlikler() {
  return (
    <>
      <section className="news-section">
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle"><span>BRASSERIE CHAGLAYAN</span></div>

            <div className="pattern-image">
              <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
            </div>

            <h2> Yaklaşan Etkinlikler </h2>
          </div>

          <div className="row justify-content-center clearfix">

            <div className="news-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                <div className="image-box">
                  <div className="date"><span>27/07/2023</span></div>
                  <div className="image"><img src={etkinlik1} alt="" /></div>
                  <div className="over-content">
                    <div className="cat">YAZ GECELERİ</div>
                    <h4>REVA</h4>
                  </div>
                </div>
              </div>
            </div>


            <div className="news-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                <div className="image-box">
                  <div className="date"><span>16/06/2023</span></div>
                  <div className="image"><img src={etkinlik2} alt="" /></div>
                  <div className="over-content">     
                  <div className="cat">YAZ GECELERİ</div>           
                    <h4>JAZZ QUARTET</h4>
                  </div>
                </div>
              </div>
            </div>


            <div className="news-block col-lg-4 col-md-6 col-sm-12">
              <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                <div className="image-box">
                  <div className="date"><span>26/08/2022</span></div>
                  <div className="image"><img src={etkinlik3} alt="" /></div>
                  <div className="over-content">
                    <div className="cat">YAZ GECELERİ</div>
                    <h4>TEST GROUP</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="lower-link-box text-center">
            <Link to="https://wa.me/905391139999" target="_blank" className="theme-btn btn-style-two clearfix">
              <span className="btn-wrap">
                <span className="text-one">REZERVASYON</span>
                <span className="text-two">REZERVASYON</span>
              </span>
              </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default Etkinlikler