import React from 'react'
import Contactbanner from '../ContactUsSect/Contactbanner'
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/background/banner-image-4.jpg'

function Main() {
  return (
    <>
      <Bredcrumb title="" subtitle="İLETİŞİM" Img={Img} />
      <Contactbanner />
    </>
  )
}

export default Main
