import React, { useState, useRef } from 'react';

const SuprizKokteyl = () => {
  const cocktails = [
    {
      name: "Hera",
      description: "strong / tropic / well balanced",
      ingredients: ["Monkey Shoulder", "Apricot Jam", "Fresh Lime", "Pistachio", "Passion Fruit"],
    },
    {
      name: "Mind Body Soul",
      description: "sweet & earthy",
      ingredients: ["Pear Infused Vodka", "Cinnamon", "Passion Fruit", "Fresh Lime", "Mint"],
    },
    {
      name: "Nicosia Sling",
      description: "earthy / casual / local",
      ingredients: ["Gin", "Coriander", "Cucumber", "Salt", "Fresh Lemon"],
    },
    {
      name: "Cosy",
      description: "sweet & sour / casual",
      ingredients: ["White Rum", "Blackberries", "Fresh Lime", "Fresh Orange", "Lime Soda"],
    },
    {
      name: "Bliss",
      description: "sweet & sour and clear",
      ingredients: ["Gin", "Vermouth", "Raspberries", "Foam"],
    },
    {
      name: "Sympathy",
      description: "sparkling & floral",
      ingredients: ["Gin", "Elderflower", "Lime", "Prosecco", "Mint"],
    },
    {
      name: "Shanti",
      description: "fresh & furity",
      ingredients: ["Gin", "Pineapple", "Strawberry Puree", "Fresh Lime", "Aperol"],
    },
    {
      name: "Suzanne",
      description: "delicate / fresh / sweet",
      ingredients: [
        "Fiero Vermuth",
        "Gin",
        "Fresh Lime",
        "Peach Puree",
        "Peach Oleo",
        "Soda",
        "Fennel",
      ],
    },
    {
      name: "The And-II",
      description: "sweet & floral",
      ingredients: [
        "Vodka",
        "Vanilla Syrup",
        "Homemade Pear & Cinnamon Syrup",
        "Fresh Lime",
        "Vegan Foam",
      ],
    },
    {
      name: "Love Hurts",
      description: "spicy / tropic / milky",
      ingredients: [
        "Tequila",
        "Simple Syrup",
        "Passion Fruit Puree",
        "Soy Milk",
        "Passion Fruit",
        "Chili Pepper Muddle",
        "Fresh Lime",
        "Triple Sec",
      ],
    },
    {
      name: "Iron",
      description: "bitter sweet & dry",
      ingredients: [
        "Tullamore D.E.W Irish Whiskey",
        "Dry Vermouth",
        "Aperol",
        "Angostura Orange Bitter",
        "Pink Grapefruit",
        "Fresh Lime",
        "Plum & Black Grape",
      ],
    },
    {
      name: "Mellow Yellow",
      description: "citrus explosion with Hint of Vanilla",
      ingredients: [
        "Gin",
        "Fresh Lime",
        "Pink Grapefruit",
        "Fresh Orange Juice",
        "Vanilla Syrup",
        "Homemade Lemonade",
        "Fresh Rosemary",
      ],
    },
    {
      name: "Beatles",
      description: "strong / fruity",
      ingredients: ["Gin", "Campari", "Dry Vermouth", "Blackberry Puree", "Angostura Orange Bitter", "Pink Grapefruit", "Thyme"],
    },
    {
      name: "Smiley",
      description: "tropical & fresh",
      ingredients: ["Pineapple Gin", "Guava Puree", "Pink Grapefruit", "Triple Sec", "Fresh Lime", "Black Pepper"],
    },
    {
      name: "Ted",
      description: "sweet / tropic with Hint of toasted almonds",
      ingredients: [
        "White Rum",
        "Fresh Lemon Juice",
        "Orgeat Syrup",
        "Pineapple Juice",
        "Violet",
      ],
    },
    {
      name: "Señoritas",
      description: "strong / floral / milky",
      ingredients: [
        "Tequila",
        "Aperol",
        "Triple Sec",
        "Coconut Milk [No Sugar]",
        "Fresh Lime",
        "Cajun Rim",
      ],
    },
    {
      name: "Queen",
      description: "fruity / acidic with touch of fresh thyme",
      ingredients: [
        "Strawberry Gin",
        "Apple Puree",
        "Triple Sec",
        "Fresh Lime",
        "Vegan Foam",
        "Ginger Ale",
        "Thyme",
        "Cucumber",
      ],
    },
    {
      name: "BC Negroni",
      description: "same soul with a classic, but different perspective / fresh",
      ingredients: ["Hendricks Gin", "Light Vermouth", "Angostura Orange Bitters", "Campari Foam"],
    },
    {
      name: "KK",
      description: "sweet / sour / fruity",
      ingredients: ["Gin", "Dry Vermouth", "Strawberry Puree", "Raspberry Syrup", "Fresh Lime", "Fresh Strawberry"],
    },
    {
      name: "Babi",
      description: "floral / sour / sparkling",
      ingredients: ["Aperol", "Fresh Lime", "Demerara Syrup", "Angostura Orange Bitter", "Prosecco"],
    },
    {
      name: "Keila",
      description: "frozen / tropic / citrus feel",
      ingredients: ["Tequila", "Mango Puree", "Homemade Lemonade", "Triple Sec", "Fresh Orange Juice"],
    },
    {
      name: "Sienna",
      description: "creamy / sweet / milky",
      ingredients: [
        "Tequila",
        "Fresh Lime",
        "Espresso",
        "Banana Syrup",
        "Creme de Cacao",
        "Coconut Milk [No Sugar]",
        "Saline",
      ],
    },
    {
      name: "Spin",
      description: "sweet / fruity with touch of fresh basil",
      ingredients: ["Gin", "Raspberry Syrup", "Fresh Lime", "Simple Syrup", "Cherry Juice", "Homemade Lemonade", "Basil", "Vegan Foam"],
    },
    {
      name: "Louis",
      description: "local / sweet and sour with hint of cinnamon",
      ingredients: ["Zivania", "Pomegranate Puree", "Cherry Juice", "Fresh Lime", "Homemade Lemonade", "Fresh Mint", "Cinnamon"],
    },
    {
      name: "Che Cha",
      description: "refreshment / sweet and sour",
      ingredients: ["Gin", "Crushed and Sliced Fresh Cucumber", "Fresh Lime", "Cucumber Syrup"],
    },
  ];


  const [isSpinning, setIsSpinning] = useState(false);
  const [randomCocktail, setRandomCocktail] = useState(null);
  const animationRef = useRef(null);

  const getRandomIndex = () => Math.floor(Math.random() * cocktails.length);

  const spinWheel = (start, duration) => {
    const now = performance.now();
    const elapsed = now - start;
    const progress = elapsed / duration;
    const rotationSpeed = 1 * Math.sin(progress * Math.PI); // Sinusoidal rotation speed (0 to 90 degrees) for slower easing

    document.documentElement.style.setProperty('--rotation-speed', `${rotationSpeed}deg`);

    if (progress < 1) {
      const randomIndex = getRandomIndex();
      setRandomCocktail(cocktails[randomIndex]);
      animationRef.current = requestAnimationFrame(() => spinWheel(start, duration));
    } else {
      setIsSpinning(false);
    }
  };

  const handleRandomize = () => {
    setIsSpinning(true);
    const duration = 2000; // Adjust the duration (milliseconds) for how long the spinning should last (5 seconds)
    const start = performance.now();
    spinWheel(start, duration);
  };

  return (
    <div className="picker-container">
      <h4>Süpriz Kokteyl</h4>
      <div className="cocktail-info">
        {isSpinning ? (
          <p>{randomCocktail ? randomCocktail.name : 'SEÇİLİYOR...'}</p>
        ) : randomCocktail ? (
          <div>
            <h2>{randomCocktail.name}</h2>
            <p>{randomCocktail.description}</p>
            <ul>
              {randomCocktail.ingredients.map((ingredient, index) => (
                <li key={index}>{ingredient}</li>
              ))}
            </ul>
          </div>
        ) : (
          <p>No cocktail selected.</p>
        )}
      </div>


   
        <button onClick={handleRandomize} disabled={isSpinning} className="theme-btn btn-style-one clearfix">

          <span className="btn-wrap">
            <span className="text-one">  {isSpinning ? 'SEÇİLİYOR...' : 'Şaşırt Beni'}</span>
            <span className="text-two">  {isSpinning ? 'SEÇİLİYOR...' : 'Şaşırt Beni'}</span>
          </span>
        </button>


   
    </div>
  );
};

export default SuprizKokteyl;