import React from 'react'
import mainchef from '../../assets/images/resource/mainchef.webp'


function BizlerSection() {
    return (
        <>
            <section className="chef-section">
             
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image"><img src={mainchef} alt="" /></div>
                            </div>
                        </div>

                        <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="inner clearfix wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="content-box">
                                    <div className="title-box">
                   
                                        <h2>Yeniden Hayat Bulan Tarihi Bir Miras</h2>
                                        <div className="text">
                                            Yemek tutkumuz ve köklerimize olan güçlü bağımızdan doğan Brasserie Chaglayan, adını 1950'lerde Lefkoşa'da gece hayatının en canlı olduğu tek mekan olan Çağlayan Gazinosu'ndan alıyor.
                                        </div>
                                        <div className="text">
                                            İşte tam da bu tarihi ve büyüleyici atmosferi günümüze taşımak, o dönemin şıklığını ve zarafetini geri getirmek için özenli bir restorasyon sürecinin ardından Brasserie Chaglayan'a hayat verdik.
                                        </div>
                                        <div className="text">
                                            Şık Art Deco detaylar eşliğinde deneyimli şeflerimizin modern yorumlarıyla buluşan dünya lezzetlerinin tadını çıkarırken, özel barımızda hazırlanan imza kokteyllerimizi yudumlayabilirsiniz.
                                        </div>
                                        <div className="text">
                                            Brasserie Chaglayan olarak, sizleri büyüleyici atmosferimizde ağırlamak ve unutulmaz anılarınıza ev sahipliği yapmak için sabırsızlanıyoruz.
                                        </div>
                                    </div>
                                    <div className="info">
                              
                                        <div className="author">Fatoş Nazım & Emrah Altunbulak</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BizlerSection