import React from 'react'
import Backgroundimage from '../../assets/images/background/image-12.jpg';

function Quotesection() {
  return (
    <>
      <section className="intro-section quote">
        <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}></div>
        <div className="auto-container">
          <div className="content-box">
          <h3>Damakta iz bırakan anılar yaratmak amacıyla çıktığımız Brasserie Chaglayan yolculuğumuzda buluşmak üzere…</h3>
           
          </div>
        </div>
      </section>
    </>

  )
}

export default Quotesection