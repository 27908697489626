import React from 'react'
import { Link } from 'react-router-dom'
import Backgroundoffer from '../../assets/images/background/image-1.jpg';



function Odalar() {
    return (

        <>
            <section className="special-dish">

             
                <div className="outer-container">
                    <div className="row clearfix">

                        <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image-layer" style={{ backgroundImage: `url(${Backgroundoffer})` }}></div>
                               
                            </div>
                        </div>

                        <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
            
                            <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">

                                <div className="title-box">
                                   
                                <div className="subtitle"><span>ÖZEL ANILARINIZ İÇİN</span></div>

                                    <div className="pattern-image">
                                        <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                    </div>

                                    <h3>Lezzetli Unutulmaz Anlar Bu Odada Buluşuyor</h3>
                                    <div className="text">Brasserie Chaglayanın üst katında bulunan konforlu odalarımız, keyifli buluşmalarınız, kutlamalarınız, davetleriniz ve özel toplantılarınız için sizleri bekliyor.</div>
                                </div>

                                <div className="link-box">
                                    <Link to="https://wa.me/905391139999" className="theme-btn btn-style-two clearfix">
                                        <span className="btn-wrap">
                                            <span className="text-one">REZERVASYON</span>
                                            <span className="text-two">REZERVASYON</span>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                
            </section>
        </>
    )
}

export default Odalar