import React from 'react';

import Specialmenu from '../Specialmenu/Specialmenu';

function SpesiyalYemekler() {


  return (
    <>
      <section className="why-us">
        <div className="left-bg">
        
        </div>
        <div className="right-bg">
         
        </div>
        <Specialmenu />
      </section>
    </>
  );
}

export default SpesiyalYemekler;
