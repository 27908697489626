import React from 'react'
import { Link } from 'react-router-dom'
import bg3 from '../../assets/images/background/bg-3.png'

import img1 from '../../assets/images/resource/image-1.webp'
import img2 from '../../assets/images/resource/image-2.webp'

function Story() {
    return (

        <>

            <section className="story-section">

                <div className="left-bg"><img src={bg3} alt="" title="" /></div>

                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="text-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="title-box centered">
                                    <div className="subtitle"><span> BRASSERIE CHAGLAYAN </span></div>
                                    <div className="pattern-image">

                                        <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                    </div>
                                    <h2>Her Lezzetin Bir Hikayesi Var</h2>
                                    <div className="text">Tüm lezzetlerimizin arkasında bir hikaye yatıyor. Şeflerimiz, dünya mutfaklarının vazgeçilmez tatlarını modern yorumlarıyla buluştururken, en taze ve yerel ürünleri kullanmaktadır. Mutfağımızdan çıkan her bir yemek, restoranımızın özenli dokunuşunu ve yaratıcılığını yansıtan bir lezzet senfonisine dönüşür.</div>
                                    <div className="text">Brasserie Chaglayan, sadece bir restoran değil, aynı zamanda bir buluşma noktasıdır. Sevdiklerinizle bir araya gelmenin keyfini, samimi ve sıcak ortamımızda yaşayacaksınız. İster romantik bir akşam yemeğinde, ister iş toplantılarında, size özel anlar yaşatmak için buradayız.</div>
                                    
                                </div>
                                <div className="booking-info">
                                    <div className="bk-title">Rezervasyon</div>
                                    <div className="bk-no"><Link href="tel:+905391139999">+90 (539) 113 9999</Link></div>

                                 

                                </div>
                            </div>
                        </div>

                        <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                              
                                <div className="images parallax-scene-1">
                                    <div className="image " data-depth="0.15"><img src={img1} alt="" /></div>
                                    <div className="image" data-depth="0.30"><img className='fore-img' src={img2} alt="" /></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>

        </>

    )
}

export default Story