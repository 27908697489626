import React from 'react'
import bg1 from '../../assets/images/background/bg-1.png'
import bg2 from '../../assets/images/background/bg-2.png'
import offerimg1 from '../../assets/images/resource/offer-image-1.webp'
import offerimg2 from '../../assets/images/resource/offer-image-2.webp'
import offerimg3 from '../../assets/images/resource/offer-image-3.webp'

function Lezzetler() {

  const offers = [
    {
      image: offerimg1,
      title: '',
      link: 'https://brasseriechaglayan.com/menu/Menu.pdf'
    },
    {
      image: offerimg2,
      title: '',
      link: 'https://brasseriechaglayan.com/menu/Menu.pdf'
    },
    {
      image: offerimg3,
      title: '',
      link: 'https://brasseriechaglayan.com/menu/Menu.pdf'
    }
  ];

  return (
    <>
      <section className="we-offer-section">
        <div className="left-bot-bg"><img src={bg1} alt="" title="" /></div>
        <div className="right-top-bg"><img src={bg2} alt="" title="" /></div>
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle"><span>ÖZENLE HAZIRLANAN SUNUMLAR</span></div>
            <div className="pattern-image">
              <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
            </div>
            <h2>İştah Kabartan Lezzetler</h2>
            <div className="text" style={{ whiteSpace: 'pre-line' }}>
              Tekrar canlandırdığımız bu tarihi binada Brasserie Chaglayan olarak konuklarımıza tarihle lezzetin kusursuz birlikteliğini yaşatıyor ve onlara büyüleyici atmosferimizde uzun süre hatırlanacak rafine bir gastronomik deneyim sunuyoruz.

            </div>
          </div>
          <div className="row justify-content-center clearfix">
            {offers.map((offer, index) => (
              <div key={index} className="offer-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box fadeInUp" data-wow-duration="1500ms" data-wow-delay={`${index * 300}ms`}>
                  <div className="image">
                    <a href="https://brasseriechaglayan.com/menu/Menu.pdf">
                      <img src={offer.image} alt="" />
                    </a>
                  </div>

                </div>
              </div>
            ))}
            <div className="link-box">
              <a href="https://brasseriechaglayan.com/menu/Menu.pdf" className="theme-btn btn-style-two clearfix">
                <span className="btn-wrap">
                  <span className="text-one">MENÜ</span>
                  <span className="text-two">MENÜ</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Lezzetler
