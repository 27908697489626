import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Bgtwo from '../../assets/images/background/image-2.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';

import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";

import SwiperCore, { Controller } from 'swiper';


SwiperCore.use([Controller]);
function Contact() {


    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        numberOfPeople: '1 Kişi',  // Default value for number of people
        date: new Date().toLocaleDateString(),  // Default value for date (today's date)
        time: '13 : 00',  // Default value for time
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            const updatedData = {
                ...prevData,
                [name]: value,
            };
            console.log(updatedData);  // Log the updated state
            return updatedData;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();


        // Build the message to send.
        const messageToSend = `Ad: ${formData.name}\nSoyad: ${formData.surname}\nKişi Sayısı: ${formData.numberOfPeople}\nTarih: ${formData.date}\nSaat: ${formData.time}\nMesaj: ${formData.message}`;

        // Encode the message.
        const encodedMessage = encodeURIComponent(messageToSend);

        // Replace with your phone number in international format without '+' or '00'.
        const phoneNumber = '905391139999';

        // Build the WhatsApp URL.
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

        // Open the URL to send the message.
        window.open(whatsappUrl);


    };



    const swiper1 = useRef(null);
    const swiper2 = useRef(null);

    const [flag, setflag] = useState(false)

    const slider2 = useRef()
    const duration = 500;
    const syncPosition = (e) => {
        if (!flag) {
            setflag(false)
            if (slider2.current) {
                slider2.current.to(e.item.index, duration)
            }
            setflag(false)
        }

    }


    const [startDate, setStartDate] = useState(new Date());
    return (
        <>
            <div className="testimonials-section" >
                <div className="image-layer" style={{ backgroundImage: `url(${Bgtwo})` }}></div>
                <div className="auto-container">
                    <div className="carousel-box owl-theme">
                        <h3>Sizlerden Gelen Yorumlar</h3>
                        <Swiper className="testi-top"
                            ref={swiper1}
                            controller={{ control: swiper2.current }}
                            items={1} loop margin={10} autoplay onChange={(e) => syncPosition(e)}>
                            <SwiperSlide className="slide-item">
                                <div className="slide-content">
                                    <div className="quotes">“</div>
                                    <div className="text quote-text">Lezzetli yemekleri ve şık mekan tasarımı ile değerli zaman geçirebileceğiniz bir mekan.</div>

                                </div>
                            </SwiperSlide >
                            <SwiperSlide className="slide-item">
                                <div className="slide-content">
                                    <div className="quotes">“</div>
                                    <div className="text quote-text">Güzel ortam, güler yüzlü çalışanlar, lezzetli yemekler. </div>
                                </div>
                            </SwiperSlide >
                            <SwiperSlide className="slide-item">
                                <div className="slide-content">
                                    <div className="quotes">“</div>
                                    <div className="text quote-text">Yemeklerimiz harikaydı, çok doymuş olmama rağmen bütün tabağı bitirdiğim nadir yerlerden biri.</div>
                                </div>
                            </SwiperSlide >
                            <SwiperSlide className="slide-item">
                                <div className="slide-content">
                                    <div className="quotes">“</div>
                                    <div className="text quote-text">Mükemmel bir ambiyans , mükemmel lezzet ve mükemmel hizmet</div>
                                </div>
                            </SwiperSlide >
                            <SwiperSlide className="slide-item">
                                <div className="slide-content">
                                    <div className="quotes">“</div>
                                    <div className="text quote-text">Her şey inanılmaz güzel! Yemekler kokteyller…</div>
                                </div>
                            </SwiperSlide >
                        </Swiper>
                        <div className="separator"><span></span><span></span><span></span></div>

                    </div>
                </div>
            </div>

            <section className="reserve-section">
                <div className="auto-container">
                    <div className="outer-box">
                        <div className="row clearfix">
                            <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="title">
                                        <h2>Rezervasyon</h2>
                                        <div className="request-info">Rezervasyon için <Link to="tel:0539 113 9999">0539 113 9999</Link> arayabilir ya da aşağıdaki formu doldurabilirsiniz.</div>
                                    </div>
                                    <div className="default-form reservation-form">
                                        <form method="post" action="/" onSubmit={handleSubmit}>
                                            <div className="row clearfix">
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                    <div className="field-inner">
                                                        <input type="text" name="name" placeholder="Ad" required onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                    <div className="field-inner">
                                                        <input type="text" name="surname" placeholder="Soyad" onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                                    <div className="field-inner">
                                                        <span className="alt-icon far fa-user"></span>
                                                        <select className="l-icon" name="numberOfPeople" onChange={handleChange} >
                                                            <option>1 Kişi</option>
                                                            <option>2 Kişi</option>
                                                            <option>3 Kişi</option>
                                                            <option>4 Kişi</option>
                                                            <option>5 Kişi</option>
                                                            <option>6 Kişi</option>
                                                            <option>7 Kişi</option>
                                                            <option>8 Kişi</option>
                                                        </select>
                                                        <span className="arrow-icon far fa-angle-down"></span>
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                                    <div className="field-inner">

                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={(date) => {
                                                                const formattedDate = date.toLocaleDateString(); // Format date to 'mm/dd/yyyy'
                                                                setStartDate(date);
                                                                handleChange({ target: { name: 'date', value: formattedDate } });
                                                            }}
                                                            minDate={new Date()}  // Disallow selecting dates before today
                                                        />
                                                        <span className="arrow-icon far fa-angle-down"></span>
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-4 col-md-12 col-sm-12">
                                                    <div className="field-inner">
                                                        <span className="alt-icon far fa-clock"></span>
                                                        <select className="l-icon" name="time" onChange={handleChange}>                   
                                                            <option>17 : 00</option>
                                                            <option>18 : 00</option>
                                                            <option>19 : 00 </option>
                                                            <option>20 : 00 </option>
                                                            <option>21 : 00 </option>
                                                            <option>22 : 00 </option>
                                                            <option>23 : 00 </option>
                                                        </select>
                                                        <span className="arrow-icon far fa-angle-down"></span>
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                    <div className="field-inner">
                                                        <textarea name="message" placeholder="Mesaj" onChange={handleChange}></textarea>

                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                    <div className="field-inner">

                                                        <button type="submit" className="theme-btn btn-style-one clearfix">
                                                            <span className="btn-wrap">
                                                                <span className="text-one">GÖNDER</span>
                                                                <span className="text-two">GÖNDER</span>
                                                            </span>
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="info-col col-lg-4 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="title">
                                        <h2>İletişim</h2>
                                    </div>
                                    <div className="data">
                                        <div className="booking-info">
                                            <div className="bk-title">Rezervasyon Numarası</div>
                                            <div className="bk-no"><Link to="tel:0539 113 9999">0539 113 9999</Link></div>
                                        </div>
                                        <div className="separator"><span></span></div>
                                        <ul className="info">
                                            <li><strong>Adres</strong><br />Şehit Albay Karaoğlanoglu Caddesi, No : 15, Lefkoşa, KKTC</li>
                                            <li><strong>Öğle Yemeği Servisi</strong><br />Pazartesi-Cumartesi <br />11.00 - 14:00</li>
                                            <li><strong>Çalışma Saatlerimiz</strong><br />Pazartesi-Cumartesi <br />12:00 - 00:00</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact