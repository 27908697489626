import React, {  useState, useEffect} from 'react'
import { Route, Routes , useLocation} from 'react-router-dom';
import Header from '../components/Header/Main';
import Footer from '../components/Footer/Main';
import Home from '../components/Home/Main';
import Hakkimizda from '../components/Hakkimizda/Main';
import Contact from '../components/Contact/Main';
import JobApplicationForm from '../components/Kariyer/Main';



function Index() {

  const [homepage, sethomepage] = useState(false)
    const location = useLocation();
    useEffect(() => {
      if (location.pathname === "/hometwo"){
        sethomepage(false)
      }else{
        sethomepage(true)
      }
    }, [location])

  const path = location.pathname
  useEffect(() => {
    window.scroll(0, 0)
}, [path]);

  return (
    <>
      {homepage && <Header/>}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/hakkimizda" element={<Hakkimizda />} />
        <Route path="/iletisim" element={<Contact />} />
        <Route path="/kariyer" element={<JobApplicationForm />}/>

      </Routes>
      <Footer />
    </>
  )
}

export default Index