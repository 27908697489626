import React, { useState } from 'react';


function JobApplicationForm() {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email:'',
        pozisyon: '',  
        deneyim:'', 
        referans: '',
        mesaj: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            const updatedData = {
                ...prevData,
                [name]: value,
            };
            console.log(updatedData);  // Log the updated state
            return updatedData;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();


        // Build the message to send.
        const messageToSend = `Ad & Soyad: ${formData.name}\nTelefon: ${formData.phone}\nE-Posta: ${formData.email}\nBaşvuru Yapılan Pozisyon: ${formData.pozisyon}\nDeneyim: ${formData.deneyim}\nMesaj: ${formData.mesaj}`;

        // Encode the message.
        const encodedMessage = encodeURIComponent(messageToSend);

        // Replace with your phone number in international format without '+' or '00'.
        const phoneNumber = '+905391139999';

        // Build the WhatsApp URL.
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;

        // Open the URL to send the message.
        window.open(whatsappUrl);

       
    };


    return (
        <>
            <section className="application-section">
                <div className="auto-container">
                    <div className="outer-box">
                        <div className="row clearfix">
                            <div className="application-col col-lg-12 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="title">
                                        <h2>İş Başvuru Formu</h2>
                                        <div className="request-info">İş başvurusunda bulunmak için formu doldurabilirsiniz.</div>
                                    </div>
                                    <div className="default-form application-form">
                                        <form method="post" action="/" onSubmit={handleSubmit}>
                                            <div className="row clearfix">
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                    <input type="text" name="name" placeholder="Ad & Soyad" required onChange={handleChange} />
                                                </div>
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                    <input type="tel" name="phone" placeholder="Telefon" required onChange={handleChange} />
                                                </div>
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                    <input type="email" name="email" placeholder="E-Posta Adresi" required onChange={handleChange} />
                                                </div>
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                    <input type="text" name="pozisyon" placeholder="Başvurulacak Pozisyon" required onChange={handleChange} />
                                                </div>
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                    <input type="text" name="deneyim" placeholder="Deneyim (Örnek: 5 Yıl)" required onChange={handleChange} />
                                                </div>
                                                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                    <textarea name="mesaj" placeholder="Mesaj" onChange={handleChange}></textarea>
                                                </div>
                                                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                    <button type="submit" className="theme-btn btn-style-one">
                                                        <span className="btn-wrap">
                                                            <span className="text-one">GÖNDER</span>
                                                            <span className="text-two">GÖNDER</span>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default JobApplicationForm;
