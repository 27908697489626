import React from 'react';
import WhatsappLogo from '../assets/images/whatsapp.svg';

const WhatsAppStickyButton = () => {
  const handleClick = () => {
    // Replace 'YOUR_WHATSAPP_LINK' with the actual WhatsApp link you want to direct users to.
    window.open('https://wa.me/905391139999', '_blank');
  };

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '15px',
        right: '15px',
        width: '75px',
        height: '75px',
        cursor: 'pointer',
        borderRadius: '50%',
      
        transition: 'transform 0.2s ease', // Smooth transition for hover effect
        zIndex: '9999',
      }}
      onClick={handleClick}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'scale(1.05)'; // Slight zoom on hover

      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'scale(1)'; // Reset zoom on mouse leave
       
      }}
    >
      <img
        src={WhatsappLogo}
        alt="WhatsApp Icon"
        style={{
          width: '100%',  // Reduced to 60% to avoid the icon touching the button edges
          height: '100%',

        }}
      />
    </div>
  );
};

export default WhatsAppStickyButton;
