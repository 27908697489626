import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import Slideone from '../../assets/images/main-slider/slider-1.webp'
import Slidetwo from '../../assets/images/main-slider/slider-2.webp'
import Slidethree from '../../assets/images/main-slider/slider-3.webp'



function Banner() {
    return (
        <Swiper className="banner-section" loop spaceBetween={50} slidesPerView={1} navigation={{ nextEl: '.swiper-button-prev', prevEl: '.swiper-button-next' }}>
            <div className="banner-container">
                <div className="banner-slider">
                    <div className="swiper-wrapper">
                        {[Slideone, Slidetwo, Slidethree].map((image, index) => (
                            <SwiperSlide key={index}>
                                <div className="swiper-slide slide-item" style={{ backgroundImage: `url(${image})` }}>
                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="subtitle">
                                                            <span>TARİH VE LEZZETİN BULUŞMA NOKTASI</span>
                                                        </div>
                                                        <div className="pattern-image">
                                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                                        </div>
                                                        <h1><span>{index === 0 ? `Brasserie Chaglayan'da` : index === 1 ? 'Tarihi Dokunuşlarla' : 'Kusursuz Lezzetleri Tamamlayan'}<br />{index === 0 ? 'Şık Bir Lezzet Deneyimi' : index === 1 ? 'Modern Lezzetler' : 'Seçkin Şaraplar'}</span></h1>
                                                        <div className="text"><span>{index === 0 ? 'En taze ve yerel malzemeler kullanılarak, ustalıkla hazırlanan yemeklerin tadını çıkarın.' : index === 1 ? 'Şeflerimizin özel tariflerini büyüleyici bir atmosferde deneyimleyin' : 'Zamana meydan okuyan şaraplarımızla, lezzet dolu bir serüvene davetlisiniz.'}</span></div>
                                                        <div className="links-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                            <div className="link">
                                                                <a href="https://brasseriechaglayan.com/menu/Menu.pdf" className="theme-btn btn-style-two clearfix" target="_blank" rel="noopener noreferrer">
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">MENÜ</span>
                                                                        <span className="text-two">MENÜ</span>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </SwiperSlide>
                        ))}
                    </div>
                </div>
            </div>
            <div className="swiper-button-prev">
                <span className="fal fa-angle-left"></span>
            </div>
            <div className="swiper-button-next">
                <span className="fal fa-angle-right"></span>
            </div>
           
        </Swiper>
    )
}

export default Banner
