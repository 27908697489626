import React from 'react'
import BizlerSection from './BizlerSection'
import Quotesection from './Quotesection'
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/background/banner-image-4.jpg'

function Main() {
  return (

    <>

      <Bredcrumb subtitle="Hikayemiz" title="Zamanda Yolculuk Eden Tatlar"  Img={Img} />
      <BizlerSection />
      <Quotesection />
    </>

  )
}

export default Main