import React from 'react'

import Kokteyl from '../Anasayfa/Kokteyl'
import Contact from '../Anasayfa/Contact'
import Banner from '../Anasayfa/Banner'
import Lezzetler from '../Anasayfa/Lezzetler'
import Hikaye from '../Anasayfa/Hikaye'
import Odalar from '../Anasayfa/Odalar'
import SpesiyalYemekler from '../Anasayfa/SpesiyalYemekler'
import Etkinlikler from '../Anasayfa/Etkinlikler'
import SliderHomePage from '../Anasayfa/SliderHomePage'
import SuprizKokteyl from '../SuprizKokteyl/Main.js'



function Main() {
    return (
        <>
            <div className="menu-backdrop"> </div>


            <Banner />
            <Lezzetler />
            <Hikaye />
            <SliderHomePage />
            <Odalar />
            <Kokteyl />
            <Contact />
            <SpesiyalYemekler />

             {/* <Etkinlikler />*/}
            <SuprizKokteyl />


        </>
    )
}

export default Main