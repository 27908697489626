import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Iframe from 'react-iframe'

import restro from '../../assets/images/resource/iletisim-form-gorseli.webp'

function Contactbanner() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Build the message to send to WhatsApp
        const messageToSend = `
            İsim: ${formData.name}
            E-Posta: ${formData.email}
            Telefon: ${formData.phoneNumber}
            Mesaj: ${formData.message}
        `;
    
        // Encode the message
        const encodedMessage = encodeURIComponent(messageToSend);
    
        // Your phone number in international format without '+' or '00'
        const phoneNumber = '905391139999';
    
        // Build the WhatsApp URL
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    
        // Open the URL to send the message to WhatsApp
        window.open(whatsappUrl);
    
    
    };
    return (
        <>
            <div className="contact-map">
                <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3260.9725720609576!2d33.36655687638904!3d35.18223615722288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14de17b9baa1fd3b%3A0xeb293e63efaec483!2sBrasserie%20Chaglayan!5e0!3m2!1sen!2s!4v1689956194534!5m2!1sen!2s" width="100%" height="500" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></Iframe>
            </div>

            <section className="contact-page">


                <div className="location-center">
                    <div className="auto-container">
                        <div className="cinfo-box">
                            <div className="row clearfix">
 {/*
                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                        <h4>Öğle Yemeği Servisi</h4>
                                        <div className="text">Pazartesi-Cumartesi<br /> 11.00 - 14:00</div>
                                    </div>
                                </div>
*/}

                                <div className="contactinfo-block col-lg-6 col-md-6 col-sm-12">
                                    <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                        <h4>İletişim</h4>
                                        <div className="text">Şehit Albay Karaoğlanoglu Caddesi, No : 15, Lefkoşa, KKTC</div>
                                        <div className="text"><Link to="mailto:info@brasseriechaglayan.com">Email : info@brasseriechaglayan.com</Link></div>

                                        <div className="more-link"><Link to="tel:+905391139999">Rezervasyon : +90 539 113 9999</Link></div>

                                    </div>
                                </div>


                                <div className="contactinfo-block col-lg-6 col-md-6 col-sm-12">
                                    <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                        <h4>Çalışma Saatlerimiz</h4>
                                        <div className="text">Pazartesi-Cumartesi<br />12:00 - 00:00</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="auto-container">
                    <div className="c-page-form-box">
                        <div className="row clearfix">

                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                                <div className="title-box centered">
                                    <h2>İletişim Formu</h2>
                                    <div className="text desc">Sorularınız, görüşleriniz ve önerileriniz bizim için değerlidir. Formu doldurarak bize ulaştığınızda, en kısa sürede sizinle iletişime geçeceğiz.</div>
                                </div>
                                <div className="default-form reservation-form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="clearfix">
                                            <div className="form-group">
                                                <div className="field-inner">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        placeholder="Ad & Soyad"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="field-inner">
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        placeholder="E-Posta Adresi"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <div className="field-inner">
                                                    <input
                                                        type="text"
                                                        name="phoneNumber"
                                                        placeholder="Telefon Numarası"
                                                        value={formData.phoneNumber}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group ">
                                                <div className="field-inner">
                                                    <textarea
                                                        name="message"
                                                        placeholder="Mesaj"
                                                        value={formData.message}
                                                        onChange={handleChange}
                                                        required
                                                    ></textarea>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <button type="submit" className="theme-btn btn-style-one clearfix">
                                                    <span className="btn-wrap">
                                                        <span className="text-one">GÖNDER</span>
                                                        <span className="text-two">GÖNDER</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>


                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                                <img src={restro} alt="" />
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Contactbanner