import React from "react";
import { Link } from "react-router-dom";
import One from '../../assets/images/resource/menu-image-11.webp'
import Two from '../../assets/images/resource/menu-image-12.webp'
import Three from '../../assets/images/resource/menu-image-13.webp'
import Four from '../../assets/images/resource/menu-image-14.webp'
import Five from '../../assets/images/resource/menu-image-15.webp'
import Six from '../../assets/images/resource/menu-image-16.webp'
import Seven from '../../assets/images/resource/menu-image-17.webp'
import Eight from '../../assets/images/resource/menu-image-18.webp'


function Specialmenu() {

  return (
    <>
      <section className="special-offer-two">
         
         
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>FAVORİ LEZZETLER</span>
              </div>
              <div className="pattern-image">
                <img src={require("../../assets/images/icons/separator.svg").default} alt="" title="" />
              </div>
              <h2>Brasserie Chaglayan Menüsünden</h2>
            </div>
            <div className="row clearfix">
             
            <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms" >
                  <div className="image">
                    <a href="https://brasseriechaglayan.com/menu/Menu.pdf">
                      <img src={Eight} alt="" />
                    </a>
                  </div>
                  <h4>
                    <a href="https://brasseriechaglayan.com/menu/Menu.pdf">Deniz Mahsullu Linguini</a>
                  </h4>
                  <div className="text desc">
                  Deniz mahsullu linguini, beyaz şarap sosu ile (kabuklu midye, karides, bebek ahtapot, kalamar)
                  </div>
                
                </div>
              </div>
             
             
     

              <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                  <div className="image">
                  <a href="https://brasseriechaglayan.com/menu/Menu.pdf">
                      <img src={Two} alt="" />
                    </a>
                  </div>
                  <h4>
                  <a href="https://brasseriechaglayan.com/menu/Menu.pdf">Dana Döş</a>
                  </h4>
                  <div className="text desc">
                  Kısık ateşte pişirilmiş dana döş, steam brokoli, dana jus sos ve fırınlanmış patates...
                  </div>
               
                </div>
              </div>

              <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms" >
                  <div className="image">
                  <a href="https://brasseriechaglayan.com/menu/Menu.pdf">
                      <img src={Three} alt="" />
                    </a>
                  </div>
                  <h4>
                  <a href="https://brasseriechaglayan.com/menu/Menu.pdf">Ahtapot</a>
                  </h4>
                  <div className="text desc">
                  Kısık ateşte pişmiş ahtapot, bourbon viski sosu, tütsülenmiş patates püresi ve kimchi...
                  </div>
               
                </div>
              </div>

              <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                  <div className="image">
                  <a href="https://brasseriechaglayan.com/menu/Menu.pdf">
                      <img src={Four} alt="" />
                    </a>
                  </div>
                  <h4>
                  <a href="https://brasseriechaglayan.com/menu/Menu.pdf">Tavada karides</a>
                  </h4>
                  <div className="text desc">
                  Tavada karides, limon & sarımsaklı tereyağı ve ekşi mayalı ekmek...
                  </div>
         
                </div>
              </div>
              <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms" >
                  <div className="image">
                  <a href="https://brasseriechaglayan.com/menu/Menu.pdf">
                      <img src={Five} alt="" />
                    </a>
                  </div>
                  <h4>
                  <a href="https://brasseriechaglayan.com/menu/Menu.pdf">Tavada Somon</a>
                  </h4>
                  <div className="text desc">
                  Tavada somon, yeşil sos, tender steam brokoli ve fırınlanmış patates...
                  </div>
         
                </div>
              </div>

              <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms" >
                  <div className="image">
                  <a href="https://brasseriechaglayan.com/menu/Menu.pdf">
                      <img src={Six} alt="" />
                    </a>
                  </div>
                  <h4>
                  <a href="https://brasseriechaglayan.com/menu/Menu.pdf">Paylaşımlık Dana bonfile</a>
                  </h4>
                  <div className="text desc">
                  Çağlayan usulü dana bonfile, humus ile
                  </div>
               
                </div>
              </div>

              <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms" >
                  <div className="image">
                  <a href="https://brasseriechaglayan.com/menu/Menu.pdf">
                      <img src={Seven} alt="" />
                    </a>
                  </div>
                  <h4>
                  <a href="https://brasseriechaglayan.com/menu/Menu.pdf">Dana Carpaccio</a>
                  </h4>
                  <div className="text desc">
                  Dana carpaccio narenciye sos ile
                  </div>
              
                </div>
              </div>
              <div className="offer-block-three col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                  <div className="image">
                  <a href="https://brasseriechaglayan.com/menu/Menu.pdf">
                      <img src={One} alt="" />
                    </a>
                  </div>
                  <h4>
                  <a href="https://brasseriechaglayan.com/menu/Menu.pdf">Çilekli Milfoy</a>
                  </h4>
                  <div className="text desc">
                  Özel Pastacı Kreması ile
                  </div>
              
                </div>
              </div>
            
            </div>
          </div>
      </section>

   
    </>
  );
}

export default Specialmenu;
