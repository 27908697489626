import React from 'react';

import { Link } from 'react-router-dom'
import logoBCFull from '../../assets/images/logoBCFull.svg'

function Main() {
    return (
        <>
            <footer className="main-footer">
                <div className="upper-section">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="footer-col info-col col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="content">
                                        <div className="logo"><Link to="/" title="Brasserie Chaglayan"><img src={logoBCFull} alt="" title="Brasserie Chaglayan" /></Link></div>
                                        <div className="info">
                                            <ul>
                                                <li>Şehit Albay Karaoğlanoglu Caddesi, No : 15, Lefkoşa, KKTC</li>
                                                <li><Link to="mailto:info@brasseriechaglayan.com">info@brasseriechaglayan.com</Link></li>
                                                <li><Link to="tel:+905391139999">Rezervasyon : +90 539 113 9999</Link></li>
                                                <li>12:00 - 00:00</li>
                                            </ul>
                                        </div>
                              
                                     
                                    </div>
                                </div>
                            </div>

                            <div className="footer-col links-col col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <Link to="/">ANASAYFA</Link></li>
                                        <li> <a href="https://brasseriechaglayan.com/menu/Menu.pdf">MENÜ</a></li>
                                        <li> <Link to="/iletisim">İLETİŞİM</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="footer-col links-col last col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <Link to="https://www.facebook.com/brasseriechaglayan" target="_blank">FACEBOOK</Link></li>
                                        <li> <Link to="https://www.instagram.com/brasseriechaglayan" target="_blank">INSTAGRAM</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="copyright">&copy; 2023 | Brasserie Chaglayan | <Link to="https://www.artte.co" >ARTTE</Link> </div>
                    </div>
                </div>

            </footer>
            <div className="scroll-to-top scroll-to-target" data-target="html"><span className="icon fa fa-angle-up"></span></div>
        </>
    )
}

export default Main